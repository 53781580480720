<template>
  <div>
    <nav aria-label="breadcrumb" class="breadcrumb-nav" style="background-color:white ;">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item" v-for="(crumb, index) in breadcrumbs" :key="index">
          <router-link :to="crumb.to">{{ crumb.label }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'ProductDetails', params: { slug: $route.params.slug } }">{{ productDetails.name
          }}</router-link>
        </li>
      </ol>
    </nav>
    <div class="product-details card">
      <div class="modal-body">
        <div class="products mt-3">
          <div class="row">
            <div class="col-lg-4">
              <!-- Product Viewer -->
              <div class="product_viewr">
                <div id="productViewr" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <slot v-if="$store.state.productInfos != null">
                      <slot v-for="(dataInfo, index) in productDetails.product_images">
                        <div class="carousel-item " :class="{ active: index == 0 }" :key="index">
                          <img :src="dataInfo.baseUrl + dataInfo.productImage" class="d-block w-100"
                            :alt="dataInfo.altName">
                        </div>
                      </slot>
                    </slot>
                  </div>
                  <!-- <pre>{{ metaProductInfo }}</pre> -->
                  <div class="carousel-tab-controller">
                    <a class="tab-prev" href="#productViewr" role="button" data-bs-slide="prev">
                      <span class="mdi mdi-chevron-left mdi-hc-2x"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <div class="carousel-indicators">
                      <slot v-if="$store.state.productInfos != null">
                        <slot v-for="(dataInfo, index) in productDetails.product_images">
                          <img data-bs-target="#productViewr" :src="dataInfo.baseUrl + dataInfo.productImage"
                            :alt="dataInfo.altName" :data-bs-slide-to="index" :class="{ active: index == 0 }"
                            :key="index">
                        </slot>
                      </slot>
                    </div>
                    <a class="tab-next" href="#productViewr" role="button" data-bs-slide="next">
                      <span class="mdi mdi-chevron-right mdi-hc-2x"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
              <!-- /Product Viewer -->
            </div>
            <div class="col-lg-7">
              <div class="content">
                <!--   <pre>{{$store.state.productInfos}}</pre> -->
                <h1 class="h2">{{ productDetails.name }}</h1>
                <h6>Reference: #MWBD-{{ productDetails.id }}</h6>
                <div class="price">
                  <slot v-if="productDetails.quantity_infos != null">
                    <slot
                      v-if="productDetails.hasDiscount == 1 && isDiscountTimeExpire(productDetails.discountStartAt, productDetails.discountEndAt)">
                      <div class="cost-price">৳ {{ productDetails.quantity_infos | sellPrice }}</div>
                      <div class="sell-price ">৳ {{ parseInt(productDetails.discount) }}</div>
                    </slot>
                    <slot v-else>
                      <div class="sell-price">৳ {{ productDetails.quantity_infos | sellPrice }}</div>
                    </slot>
                  </slot>
                </div>
                <div class="qty">
                  <button type="button" v-on:click="productQuantityChange(-1)">
                    <i class="mdi mdi-minus"></i>
                  </button>
                  <input type="number" min="1" value="1" v-model="quantity">
                  <button type="button" v-on:click="productQuantityChange(1)">
                    <i class="mdi mdi-plus"></i>
                  </button>
                </div>
                <slot v-if="productDetails != null">
                  <slot v-if="productDetails.hasSizeVarity == 1 || productDetails.hasColorVarity == 1">
                    <div class="d-flex align-items-center mb-3">
                      <slot v-if="productDetails.hasColorVarity == 1">
                        <div class="color">
                          <div class="form-check form-check-inline pl-0">
                            <strong>Color: </strong>
                          </div>
                          <div class="form-check form-check-inline pl-0">
                            <select class="form-control shadow-none w-auto" v-model="colorCode"
                              v-on:change="getSizeAndColorWisePrice()">
                              <slot v-for="colorInfo in  uniqueColors(productDetails.quantity_infos)">
                                <slot v-if="colorInfo.color_info != null">
                                  <option :value="colorInfo.color_info.id">{{ colorInfo.color_info.color }}</option>
                                </slot>
                              </slot>
                            </select>
                          </div>
                        </div>
                      </slot>
                      <slot v-if="productDetails.hasSizeVarity == 1">
                        <div class="size pl-3">
                          <div class="form-check form-check-inline pl-0">
                            <strong>Size: </strong>
                          </div>
                          <div class="form-check form-check-inline pl-0">
                            <!-- <pre>{{uniqueSizes(productDetails.quantity_infos)}}</pre> -->
                            <select class="form-control shadow-none w-auto" v-model="sizeCode"
                              v-on:change="getSizeAndColorWisePrice()">
                              <slot v-for="sizeInfo in  uniqueSizes(productDetails.quantity_infos)">
                                <slot v-if="sizeInfo.size_info != null">
                                  <option :value="sizeInfo.size_info.id">{{ sizeInfo.size_info.size }}</option>
                                </slot>
                              </slot>
                            </select>
                          </div>
                        </div>
                      </slot>
                    </div>
                  </slot>
                </slot>
                <slot v-if="productDetails.quantity_infos != null">
                  <slot v-if="quantity <= getProductQuantity(productDetails.quantity_infos)">
                    <button type="button" class="cart"
                      v-on:click="addToCartSpecificProduct(productDetails.id, productDetails.hasSizeVarity, productDetails.hasColorVarity)">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Buy Now</span>
                    </button>
                  </slot>
                </slot>
                <p class="des" v-html="productDetails.shortDetails"></p>
                <p class="des" v-html="productDetails.details"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="products mt-3">
      <div class="row align-items-center mb-3">
        <div class="col-lg-8">
          <h2 class="title pb-0 mb-0">Recommended Product</h2>
        </div>

      </div>
      <div class="grid">
        <!-- <pre>{{relatedProducts}}</pre> -->
        <div class="grid-item" v-for="(product, index) in relatedProducts" :key="index">
          <div class="pro-card card shadow-sm">
            <slot v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
              <span class="pro-badge offer" data-label="Offer"></span>
            </slot>

            <slot v-if="getProductQuantity(product.quantity_infos) < 1">
              <span class="pro-badge sold-out" data-label="Sold Out"></span>
            </slot>


            <slot v-if="product.product_image != null">
              <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">
                <img v-lazy="product.product_image.baseUrl + product.product_image.productImage" class="d-block w-100"
                  :alt="product.name">
              </router-link>
            </slot>
            <div class="card-body">
              <h3>
                <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">{{ product.name }}
                </router-link>
              </h3>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="content">
                <div class="price">
                  <slot v-if="product.quantity_infos != null">
                    <slot
                      v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
                      <div class="cost-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                      <div class="sell-price ">৳ {{ parseInt(product.discount) }}</div>
                    </slot>
                    <slot v-else>
                      <div class="sell-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                    </slot>
                  </slot>
                </div>
                <slot v-if="product.quantity_infos != null">
                  <slot v-if="getProductQuantity(product.quantity_infos) > 0">
                    <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }" type="button"
                      class="cart" v-if="product.hasSizeVarity == 1 || product.hasColorVarity == 1">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </router-link>
                    <button type="button" class="cart" v-on:click="addToCart(product.id)" v-else>
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </button>
                  </slot>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import moment from "moment";
import $ from 'jquery';

export default {
  computed: {
    breadcrumbs() {
      const breadcrumbs = [];
      const matchedRoutes = this.$route.matched;

      matchedRoutes.forEach((route) => {
        if (route.name) {
          breadcrumbs.push({ label: route.name, to: { name: route.name } });
        }
      });

      return breadcrumbs;
    },
  },
  data() {
    return {
      productDetails: [],
      quantity: 1,
      productId: null,
      slug: this.$route.params.slug,
      sizeCode: 0,
      colorCode: 0,
      metaProductInfo: {},
      metaProductImage: null,
      relatedProducts: [],
    }
  },
  metaInfo() {
    return {
      title: `${this.metaProductInfo.metaTtile} `,
      meta: [
        { name: 'author', content: "Makeup World BD" },
        { name: 'keywords', content: this.metaProductInfo.metaKey },
        { name: 'description', content: this.metaProductInfo.metaDetails },
        { name: 'title', content: this.metaProductInfo.metaTtile },
        { property: 'og:title', content: this.metaProductInfo.metaTtile },
        { property: 'og:site_name', content: 'Makeup World BD' },
        { property: 'og:description', content: this.metaProductInfo.metaDetails },
        { property: 'og:type', content: 'products' },
        { property: 'og:url', content: location.href },
        { property: 'og:image', content: this.metaProductImage }
      ]
    }
  },
  beforeMount() {
    this.getProductInfo();

  },
  mounted() {
    // this.getProductInfo();
    this.getRalatedProducts();
  },
  watch: {
    $route(to, from) { this.productDetails = []; this.getProductInfo(); this.getRalatedProducts(); }
  },
  methods: {
    setMetaInfo() {
      $("title").text(this.metaProductInfo.metaTtile);
      $('.title').attr('content', this.metaProductInfo.metaTtile);
      $('.description').attr('content', this.metaProductInfo.metaDescription);
      $('.keyword').attr('content', this.metaProductInfo.metaKeywords);
      $('.image').attr('content', this.metaProductImage);
      $('.url').attr('content', location.href);

      // Set Twitter meta tags
      $('meta[name="twitter:card"]').attr('content', 'summary_large_image');
      $('meta[name="twitter:title"]').attr('content', this.metaProductInfo.metaTtile);
      $('meta[name="twitter:description"]').attr('content', this.metaProductInfo.metaDescription);
      $('meta[name="twitter:image"]').attr('content', this.metaProductImage);


      $('.author').attr('content', this.metaProductInfo.metaAuthor);
      $('meta[name="keywords"]').attr('content', this.metaProductInfo.metaKey);
      $('meta[name="description"]').attr('content', this.metaProductInfo.metaDetails);
      $('meta[property="og:title"]').attr('content', this.metaProductInfo.metaTtile);
      $('meta[property="og:site_name"]').attr('content', 'Makeup World BD');
      $('meta[property="og:description"]').attr('content', this.metaProductInfo.metaDetails);
      $('meta[property="og:type"]').attr('content', 'products');
      $('meta[property="og:url"]').attr('content', location.href);
      $('meta[property="og:image"]').attr('content', this.metaProductImage);
    },

    getProductDetails(productId, productName) {
      this.$router.push({ name: 'ProductDetails', params: { productId: productId, slug: productName.split(' ').join('-') } });

    },
    addToCart(productId) {
      let cartInfos = this.$store.state.cartInfos;

      let formData = new FormData();

      let flag = false;

      cartInfos.forEach(cartInfo => {
        if (
          cartInfo.productId == productId &&
          cartInfo.isFreeProduct != true &&
          parseInt(cartInfo.colorId) == 0 &&
          parseInt(cartInfo.sizeId) == 0
        ) {
          let quantity = parseFloat(cartInfo.quantity) + 1;
          formData.append("productId[]", productId);
          formData.append("quantity[]", quantity);
          formData.append("isFreeProduct[]", false);
          formData.append("colorCode[]", cartInfo.colorId);
          formData.append("sizeCode[]", cartInfo.sizeId);
          formData.append("addedToCart[]", true);
          flag = true;
        }
        else
          if (cartInfo.isFreeProduct != true) {

            formData.append("productId[]", cartInfo.productId);
            formData.append("quantity[]", cartInfo.quantity);
            formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
            formData.append("colorCode[]", cartInfo.colorId);
            formData.append("sizeCode[]", cartInfo.sizeId);
            formData.append("addedToCart[]", false);
          }
      });
      if (flag != true) {
        formData.append("productId[]", productId);
        formData.append("quantity[]", 1);
        formData.append("isFreeProduct[]", false);
        formData.append("colorCode[]", 0);
        formData.append("sizeCode[]", 0);
        formData.append("addedToCart[]", false);
      }


      // let formData=new FormData();
      //  formData.append("productId",productId);
      //  formData.append("colorCode",this.colorCode);
      //  formData.append("sizeCode",this.sizeCode);
      //  formData.append("quantity",1);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };


      this.$http.post("product/add/to/cart", formData, config)
        .then(response => {
          if (response.data.msgFlag == true) {
            // console.dir(response.data);
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.success(response.data.msg);
          }
          if (response.data.errMsgFlag == true) {
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.warning(response.data.errMsg);
          }
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    getRalatedProducts() {
      // this.$http.get("get/new/products")
      this.$http.get("get/related/products", { params: { dataId: this.productId } })
        .then(response => {
          if (response.data.msgFlag == true)
            this.relatedProducts = response.data.dataList;
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    getProductInfo() {
      let slug = this.$route.params.slug;
      this.$http.get("product/details", { params: { slug: slug } })
        .then(response => {
          if (response.data.msgFlag == true) {
            this.productDetails = response.data.dataInfos;
            this.metaProductInfo = response.data.dataInfos;
            this.productId = response.data.dataInfos.id;
            if (this.metaProductInfo != null) {
              if (this.metaProductInfo.product_images.length > 0)
                this.metaProductImage = this.metaProductInfo.product_images[0].baseUrl + this.metaProductInfo.product_images[0].productImage;
            }
            // this.$store.commit("storeProductDetails",productDetails);

            let schemaTag = this.$options.filters.schemaFilter(this.metaProductInfo);//this.schemaFilter(this.metaProductInfo);

            $("#schema").text(JSON.stringify(schemaTag));
            $('link[rel=canonical]').attr('href', location.href);

            // this.setMetaInfo();

          }
          else {
            this.errMsgFlag = response.data.errMsgFlag;
            this.errMsg = response.data.errMsg;
            this.$toaster.warning(this.errMsg);
          }
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    productQuantityChange(value) {
      if (value == -1 && parseInt(this.quantity) > 1) {
        this.quantity = parseInt(this.quantity) - 1;
      }
      if (value == 1) {
        this.quantity = parseInt(this.quantity) + 1;
      }

      this.addToCartSpecificProduct();
    },
    getSizeAndColorWisePrice() {
      this.productId = this.$store.state.productId;
      this.$http.get("product/details/size/color/wise", { params: { productId: this.productId, colorId: this.colorCode, sizeId: this.sizeCode } })
        .then(response => {
          if (response.data.msgFlag == true)
            this.$store.commit("storeProductDetails", response.data.dataInfos);
          // if(response.data.errMsgFlag==true)
          //   // this.$toaster.warning(response.data.errMsg);
        })
        .catch(error => { console.dir(error) })
        .finally();

    },
    uniqueSizes(quantityInfos) {
      let sizes = [];
      quantityInfos.forEach(quantityInfo => {
        if (!sizes.some(color => color.sizeId === quantityInfo.sizeId))
          sizes.push(quantityInfo);
      });
      return sizes;
    },
    uniqueColors(quantityInfos) {
      let colors = [];
      quantityInfos.forEach(quantityInfo => {
        if (!colors.some(color => color.colorId === quantityInfo.colorId))
          colors.push(quantityInfo);
      });
      return colors;
    },
    isDiscountTimeExpire(startAt, endAt) {
      endAt = moment(endAt); //Date format

      startAt = moment(startAt); //Date format

      let today = moment();

      let isAfter = moment(endAt).isAfter(today);

      let isBefore = moment(startAt).isBefore(today);

      if (isAfter && isBefore)
        return true;
      else
        return false;
    },
    addToCartSpecificProduct(productId, hasSizeVarity, hasColorVarity) {
      if (this.addToCartValidation(hasSizeVarity, hasColorVarity)) {

        let cartInfos = this.$store.state.cartInfos;

        let formData = new FormData();

        let flag = false;

        // this.productId=this.$store.state.productId;

        cartInfos.forEach(cartInfo => {
          if (
            cartInfo.productId == this.productId &&
            cartInfo.isFreeProduct != true &&
            parseInt(cartInfo.colorId) == this.colorCode &&
            parseInt(cartInfo.sizeId) == this.sizeCode
          ) {
            let quantity = parseFloat(cartInfo.quantity) + 1;
            formData.append("productId[]", this.productId);
            formData.append("quantity[]", this.quantity);
            formData.append("isFreeProduct[]", false);
            formData.append("colorCode[]", this.colorCode);
            formData.append("sizeCode[]", this.sizeCode);
            formData.append("addedToCart[]", true);
            flag = true;
          }
          else
            if (cartInfo.isFreeProduct != true) {

              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", false);
            }
        });

        if (!flag) {
          formData.append("productId[]", this.productId);
          formData.append("quantity[]", this.quantity);
          formData.append("isFreeProduct[]", false);
          formData.append("colorCode[]", this.colorCode);
          formData.append("sizeCode[]", this.sizeCode);
          formData.append("addedToCart[]", true);

        }

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };


        this.$http.post("product/add/to/cart", formData, config)
          .then(response => {
            if (response.data.msgFlag == true) {
              // console.dir(response.data);
              this.$store.commit("storeCartInfos", response.data.cartInfos);
              this.$store.commit("storePriceInfos", response.data);
              this.$toaster.success(response.data.msg);
            }
            if (response.data.errMsgFlag == true) {
              this.$store.commit("storeCartInfos", response.data.cartInfos);
              this.$store.commit("storePriceInfos", response.data);
              this.$toaster.warning(response.data.errMsg);
            }
          })
          .catch(error => { console.dir(error) })
          .finally();
      }
    },
    addToCartValidation(hasSizeVarity, hasColorVarity) {
      if (hasSizeVarity == 1 && hasColorVarity == 1) {
        if (this.colorCode != 0 && this.sizeCode != 0) {
          return true;
        }
        else {
          if (this.sizeCode == 0)
            this.$toaster.warning("Choose A Size First.");
          if (this.colorCode == 0)
            this.$toaster.warning("Choose A Color First.");

          return false;
        }
      }
      else {
        if (hasSizeVarity == 1) {
          if (this.sizeCode != 0) {
            return true;
          }
          else {
            this.$toaster.warning("Choose A Size First.");
            return false;
          }
        }
        else
          if (hasColorVarity == 1) {
            if (this.colorCode != 0) {
              return true;
            }
            else {
              this.$toaster.warning("Choose A Color First.");
              return false;
            }
          }
          else {
            this.colorCode = 0;
            this.sizeCode = 0;
            return true;
          }
      }
      // if(hasSizeColorVarity==1){
      //   if(this.colorCode!=0 && this.sizeCode!=0){
      //     return true;
      //   }
      //   else{
      //     if(this.colorCode==0)
      //       this.$toaster.warning("Choose A Color First.");
      //     if(this.sizeCode==0)
      //       this.$toaster.warning("Choose A Size First.");
      //     return false;
      //   }
      // }
      // else{
      //   return true;
      // }
    },

    getQuantityLabel(quantityType) {
      let label = "1 PCS";
      if (quantityType != null) {
        label = quantityType.smallQuantity + " " + quantityType.label;
      }
      return label;
    },

    getSpecificSellPrice(stockInfos) {
      let sellPrice = 0;
      stockInfos.forEach(stockInfo => {
        if (stockInfo.frontend_stock_infos != null) {
          stockInfo.frontend_stock_infos.forEach(stock => {
            if (this.colorCode == stock.colorId && this.sizeCode == stock.sizeId) {
              sellPrice = parseInt(stock.sellPrice);
              // this.colorCode=stockInfo.stock_info.colorId;
              // this.sizeCode=stockInfo.stock_info.sizeId;
            }
          });
        }
      });
      if (sellPrice == 0) {
        stockInfos.forEach(stockInfo => {
          if (stockInfo.frontend_stock_infos != null) {
            stockInfo.frontend_stock_infos.forEach(stock => {
              sellPrice = parseInt(stock.sellPrice);
              // this.colorCode=stockInfo.stock_info.colorId;
              // this.sizeCode=stockInfo.stock_info.sizeId;
            });
          }
        });
      }
      return parseInt(sellPrice);
    },

    getProductQuantity(stockInfos) {

      let quantity = 0;
      stockInfos.forEach(stockInfo => {
        quantity = quantity + stockInfo.quantity;
      });

      return quantity;
    },
    schemaFilter(productInfo) {
      // productInfo['@context'] = "http://schema.org";
      // productInfo['@type'] = "Product";
      // return productInfo



      let schemaInfo = {};
      schemaInfo['@context'] = "http://schema.org";
      schemaInfo['@type'] = "Product";

      // Essential Fields
      schemaInfo.name = productInfo.name;
      schemaInfo.description = productInfo.metaDetails;
      // schemaInfo.brand = {
      //     '@type': "Brand",
      //     name: "Your Brand Name"
      // };


      if (productInfo.product_images.length > 0)
        schemaInfo.image = productInfo.product_images[0].baseUrl + productInfo.product_images[0].productImage;

      schemaInfo.url = window.location.href;

      schemaInfo.productID = productInfo.id; // Optional, if applicable

      // Additional Fields
      // schemaInfo.manufacturer = {
      //     '@type': "Organization",
      //     name: "Manufacturer Name"
      // };

      schemaInfo.model = productInfo.model;
      schemaInfo.sku = productInfo.code;

      // schemaInfo.weight = {
      //     '@type': "QuantitativeValue",
      //     value: "10",
      //     unitCode: "KG"
      // };

      // schemaInfo.color = "Product Color";
      // schemaInfo.material = "Material Used";

      // Offer Details
      schemaInfo.offers = {
        '@type': "Offer",
        price: this.$options.filters.sellPrice(productInfo.quantity_infos),
        priceCurrency: "BDT",
        availability: "https://schema.org/InStock",
        // priceValidUntil: "2024-12-31", // Optional, if there's a limited-time offer
        seller: {
          '@type': "Organization",
          name: "Makeup World BD",
          url: "https://makeupworldbd.com",
          logo: "https://makeupworldbd.com/favicon.ico"
        }
      };

      // Reviews and Ratings
      schemaInfo.aggregateRating = {
        '@type': "AggregateRating",
        ratingValue: Math.floor(Math.random() * (5 - 3)) + 3,
        // reviewCount: "25"
      };

      // Include more fields as per your product's characteristics

      return schemaInfo;
    }
  }
}
</script>
  
<style></style>